<template>
  <div class="select-question-wrapper">
    <div class="check-question-wrapper">
      <p>Danh sách câu hỏi đã chọn</p>
      <v-row>
        <v-col cols="10">
          <div class="radio-wrapper">
            <span class="checkbox-item"
                  v-for="(item,index) in checked_questions"
                  :key="index"
            >
              <input
                  type="checkbox"
                  disabled
                  id="vehicle1"
                  name="vehicle1"
                  v-model="item.check"
                  class="mr-2 checkbox-round"
              >
              <label for="vehicle1">Câu {{ item.index }}</label><br>
            </span>
          </div>
        </v-col>
        <v-col cols="2">
          <v-btn @click="saveExamQuestion">Cập nhật đề thi</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col class="text-center" cols="12">
        <h4>
          <v-btn @click="goQuestionManager" class="primary">Quản lý câu hỏi</v-btn>
        </h4>
      </v-col>
      <v-col cols="7">

        <v-data-table :headers="headers" :items="groupQuestions" class="elevation-1" item-key="title">
          <template v-slot:top>
            <v-row class="mt-3 ml-5">
              <v-col>
                <v-text-field
                    outlined
                    v-model="title"
                    hide-details
                    dense
                    rounded
                    ref="search_title"
                    color="#158FB6"
                    placeholder="Tên nhóm"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    outlined
                    v-model="part_id"
                    hide-details
                    dense
                    rounded
                    ref="search_part_id"
                    color="#158FB6"
                    placeholder="Part"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    outlined
                    v-model="from"
                    hide-details
                    dense
                    rounded
                    ref="search_from"
                    color="#158FB6"
                    placeholder="Từ"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    outlined
                    v-model="to"
                    hide-details
                    dense
                    rounded
                    ref="search_to"
                    color="#158FB6"
                    placeholder="Đến"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <hnr-button
                    :text="'Search'"
                    :color="'primary'"
                    :rounded=true
                    @hnrClickEvent="Search"
                ></hnr-button>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.title="{ item }">
            <div v-html="item.title.length>250?(item.title.substr(0,250)+'...'):item.title"></div>
          </template>
          <template v-slot:item.group_question_type_id="{ item }">
            <div v-if="item.part.group_question_type_id === 1">Nhóm riêng</div>
            <div v-else-if="item.part.group_question_type_id === 2">Nhóm chung</div>
          </template>
          <template v-slot:item.from="{ item }">
            {{item.part.from}}
          </template>
          <template v-slot:item.to="{ item }">
            {{item.part.to}}
          </template>
          <template v-slot:item.created_date="{ item }">
            {{formatDate(item.created_date)}}
          </template>
          <template v-slot:item.list="{ item }">
            <v-btn
                small
                class="mr-2"
                @click="fetchQuestionListBy(item.id)"
            >
              câu hỏi
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="5">
        <v-data-table :headers="questions_header" :items="questionsByGroup" class="elevation-1">
          <template v-slot:item.content="{ item }">
            <div v-if="!item.content">Dạng câu hỏi không có tiêu đề</div>
            <div v-else v-html="item.content"></div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn @click="viewQuestion(item)">Xem chi tiết</v-btn>
          </template>
          <template v-slot:item.check="{ item }">
            <input
                @change="selectQuestion($event,item)"
                v-model="item.check"
                id="vehicle"
                name="vehicle"
                type="checkbox"
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {GET_GROUP_QUESTION_LIST_BY_CONFIG, GET_GROUP_QUESTION_DETAIL} from "@/store/group-questions.module";
import {GET_EXAM_CONFIG, SAVE_EXAM_QUESTION} from "@/store/exams.module";
import {mapGetters} from "vuex";
import {GET_QUESTION_BY_GROUP, GET_QUESTION_BY_EXAM} from "@/store/questions.module";
import {SUCCESS} from "@/store/alerts.module";
import {EXAM_CONFIG_ID} from "@/common/constants";
import HnrButton from "@/components/elements/hnr-button"

export default {
  name: "select-question",
  components:{
    HnrButton
  },
  data() {
    return {
      title: '',
      part_id: '',
      from: '',
      to: '',
      search: '',
      EXAM_CONFIG_ID: EXAM_CONFIG_ID,
      exam_config_id: this.$route.query.exam_config_id,
      exam_id: this.$route.query.exam_id,
      headers: [
        {text: "Tên nhóm", value: "title"},
        {text: "Part", value: "part.id"},
        {text: "Vị trí", value: "questionGroupNum"},
        {text: "Câu bắt đầu", value: "from"},
        {text: "Câu kết thúc ", value: "to"},
        {text: "Ngày tạo", value: "created_date"},
        {text: "", value: "list"},
      ],
      questions_header: [
        {text: "Index", value: "index"},
        {text: "Nội dung", value: "content"},
        {text: "Hành động", value: "action"},
        {text: "", value: "check"},
      ],
      checked_item: {},
      question_in_group_one: [],
    }
  },
  async created() {
    await this.fetchExamConfig();
    await this.initCheckedQuestions();
    if(this.exam_config_id == EXAM_CONFIG_ID.topicII_writing){
      await this.fetchQuestion();
    }else{
      await this.fetchGroupQuestion();
    }
    await this.fetchQuestionByExam();
    await this.asyncCheckedQuestion();
    await this.checkListQuestion();
  },
  computed: {
    ...mapGetters({
      groupQuestions: "groupQuestionListByConfig",
      questionsByGroup: "questionListByGroup",
      questionsByExam: "questionListByExam",
      examConfigs: "examConfigListByLevel",
      examConfig: "examConfigDetail",
      groupQuestion: "group_detail",
      checked_questions: "checked_questions",
      selected_questions: "selectedQuestion",
    }),
  },
  methods: {
    Search() {
      this.$store.dispatch(GET_GROUP_QUESTION_LIST_BY_CONFIG, {
        title: this.title,
        part_id:  this.part_id,
        from:  this.from,
        to:  this.to,
        exam_config_id: this.exam_config_id
      });
      this.title = ''
      this.part_id = ''
      this.from = ''
      this.to = ''
    },
    formatDate(date) {
      if (!date) return null
      let [dateFormat , ] = date.split(' ')
      const [year, month, day] = dateFormat;
      return dateFormat
    },
    async fetchExamConfig(){
      await this.$store.dispatch(GET_EXAM_CONFIG, this.exam_config_id);
    },
    async fetchQuestionByExam(){
     await this.$store.dispatch(GET_QUESTION_BY_EXAM, {
        exam_id: this.exam_id
      });
    },
    async asyncCheckedQuestion() {
      let addSelectArray = this.addSelectArray;
      let formatSelectTable = this.formatSelectTable;
      this.questionsByExam.forEach(function (item) {
        if (item.question) {
          item.question.check = true;
          addSelectArray(item.question);
          formatSelectTable(item.question);
        }

      })
    },
    async initCheckedQuestions() {
      if (this.checked_questions.length == 0) {
        if (this.exam_config_id == 1) {
          for (let i = 1; i <= this.examConfig.question_number; i++) {
            this.checked_questions.push({
              index: i,
              check: false
            })
          }
        } else {
          for (let i = 101; i <= this.examConfig.question_number+100; i++) {
            this.checked_questions.push({
              index: i,
              check: false
            })
          }
        }
      }
    },
    async fetchQuestionListBy($group_question_id) {
      await this.$store.dispatch(GET_QUESTION_BY_GROUP, {
        group_question_id: $group_question_id
      });
      await this.$store.dispatch(GET_GROUP_QUESTION_DETAIL, {
        group_question_id: $group_question_id
      });
      await this.checkListQuestion();
    },
    checkListQuestion() {
      let selected_questions = this.selected_questions
      this.questionsByGroup.forEach(function (question, index, array) {
        let idx = selected_questions.findIndex(function (element) {
          return element.id === question.id
        })
        if (idx >= 0) {
          array[index].check = true;
        }
      })
    },
    fetchGroupQuestion() {
      this.$store.dispatch(GET_GROUP_QUESTION_LIST_BY_CONFIG, {
        exam_config_id: this.exam_config_id
      });
    },
    async fetchQuestion() {
      await this.$store.dispatch(GET_QUESTION_BY_GROUP, {
        group_question_id: null
      });
      await this.checkListQuestion();
    },
    undoCheck(e, item) {
      e.target.checked = false;
      item.check = false;
    },
    selectQuestion(e, item) {
      if (this.checkExistedGroup()) {
        this.$store.dispatch(SUCCESS, {
          type: "eror_vote",
          message: "Nhóm câu hỏi này đã được tạo!"
        }, {
          root: true,
        });
        this.undoCheck(e, item)
        return null;
      }
      if (!this.checkExistedIndex(item)) {
        this.$store.dispatch(SUCCESS, {
          type: "eror_vote",
          message: "Index bạn chọn đã tồn tại!"
        }, {
          root: true,
        });
        this.undoCheck(e, item)
        return null;
      }
      this.addSelectArray(item);
      this.formatSelectTable(item);
    },
    getQuestionInGroupOne() {
      if (this.groupQuestion.group_question_type_id == 1) {
        for(let i = this.groupQuestion.from; i <= this.groupQuestion.to; i++ ) {
          this.question_in_group_one.push(i)
        }
      }
    },
    checkExistedGroup() {
      let groupQuestion = this.groupQuestion
      const _this = this;
      console.log(groupQuestion,'groupQuestion')
      if (groupQuestion.group_question_type_id !== 1) {
        return false;
      }
      let flag = false;
      let selected_questions = this.selected_questions
      this.questionsByGroup.forEach(function (item) {
        let found_item = selected_questions.find(function (element) {
          return element.index === item.index
        })
        if (found_item && found_item.group_question_id !== groupQuestion.id) {
          console.log(_this.question_in_group_one)
          if (_this.question_in_group_one .length > 0){
            _this.question_in_group_one.find(function (element) {
              return element.index === item.index
            })
          }
          flag = true;
        }
      })
      return flag;
    },
    checkExistedIndex(item) {
      let index = this.selected_questions.findIndex(function (element) {
        return element.index === item.index
      })
      return !(item.check && index >= 0);
    },

    addSelectArray(item) {
      let index = this.selected_questions.findIndex(function (element) {
        return element.id === item.id
      })
      if (item.check && index < 0) {
        this.selected_questions.push(item);
      } else if (!item.check && index >= 0) {
        this.selected_questions.splice(index, 1);
      }
    },
    formatSelectTable(item) {
      let idx = this.checked_questions.findIndex(function (element) {
        return element.index === item.index
      })
      if (item.check && idx >= 0) {
        this.checked_questions[idx].check = true;
      } else if (!item.check && idx >= 0) {
        this.checked_questions[idx].check = false;
      }
    },
    goQuestionManager() {
      let routeData = null;
      if (this.exam_config_id == EXAM_CONFIG_ID.topicII_writing) {
        routeData = this.$router.resolve({
          path: "question/list",
          query: {exam_config_id: this.exam_config_id},
        });
      } else {
        routeData = this.$router.resolve({
          path: "/group-question/list",
          query: {
            exam_config_id: this.exam_config_id,
            group_question_id: null,
          },
        });
      }
      window.open(routeData.href, '_self');
    },
    saveExamQuestion() {
      this.$store.dispatch(SAVE_EXAM_QUESTION, {
        exam_id: this.exam_id,
        questions: this.selected_questions,
      }).then((data) => {
        if (data.status) {
          this.$store.dispatch(SUCCESS, {
            type: "success_vote",
            message: "Cập nhật đề thi thành công!"
          }, {
            root: true,
          });
        } else {
          this.$store.dispatch(SUCCESS, {
            type: "error_vote",
            message: "Cập nhật đề thi không thành công!"
          }, {
            root: true,
          });
        }
      });
    },
    viewQuestion(item) {
      let routeData = this.$router.resolve({
        path: "/question/detail",
        query: {
          question_id: item.id,
          exam_config_id: this.exam_config_id,
          group_question_id: this.groupQuestion.id,
        },
      });
      window.open(routeData.href, '_self');
    }
  }
}

</script>

<style lang="scss">

.select-question-wrapper {
  img {
    max-width: 100%;
  }

  .v-data-table {
    overflow-y: scroll;
    height: 80vh;
    border: 1px solid burlywood;
  }

  .check-question-wrapper {
    padding: 20px;
  }

  .checkbox-item {
    min-width: 80px;
  }

  .radio-wrapper {
    width: 100%;
    display: flex;
    flex-flow: wrap;

    .v-input {
      margin-top: 0;
    }

  }

  input[type=checkbox] {

  }
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: gray;
}
</style>
